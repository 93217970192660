<template>
  <div class="w-full flex grey-bg">
    <div class="email-container mx-auto py-8">
      <div class="text-center mb-8">
        <img src="/images/logo.png" alt="Fairpy Logo" class="mx-auto" />
      </div>
      <div class="bg-white">
        <img
          src="/images/emails/welcome-email-banner.png"
          class="w-full h-auto"
        />
        <div class="flex flex-col items-center md:px-8 px-4">
          <h1
            class="text-4xl font-display font-bold text-black-light text-center"
          >
            Welcome to FairPY
          </h1>
          <p class="text-center text-black-light mt-8">
            Well done John! By signing up, you've taken your first step towards
            choosing your suitable car
          </p>
          <hr class="w-full my-8" />
          <p class="text-center text-black-lighter">
            We are delighted to have you as a member in FairPY and also would
            like to inform you that you can start bidding your desired vehicle.
          </p>
          <p class="text-center text-black-lighter mt-8">
            Les Lorem Ipsum est simplement du est fauex texte employé ese dans
            la composition et la mise en page avant impression. Le Lorem Ipsum
            est le faux texte standard de l'imprimerie depuis les années.
          </p>
          <p class="text-black-light text-lg font-display mt-12 font-medium">
            We appreciate your time
          </p>
          <p class="text-center text-5xl font-cursive text-blue mt-4 mb-16">
            Thank you!
          </p>
        </div>
      </div>
      <div>
        <div class="text-center my-8">
          <img src="/images/logo-gray.png" alt="Fairpy Logo" class="mx-auto" />
        </div>
        <div class="flex flex-row justify-center">
          <a href="https://www.facebook.com/gofairpy/" target="_blank"
            ><img src="/images/icons/fb-gray.png"
          /></a>
          <a class="ml-2" href="https://twitter.com/gofairpy" target="_blank"
            ><img src="/images/icons/twitter-gray.png"
          /></a>
          <a
            class="ml-2"
            href="https://www.instagram.com/gofairpy/"
            target="_blank"
            ><img src="/images/icons/insta-gray.png"
          /></a>
        </div>
        <hr class="w-full my-6" />
        <p class="text-xs text-gray-medium text-center">
          880 SW 15th St #3, Forest Lake, MN 55025, USA<br />
          Copyright © 2021 FairPY, All rights reserved.
          <a href="">Unsubscribe</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
body,
.grey-bg {
  background: #f7f7f7;
}
@media screen and (min-width: 769px) {
  .email-container {
    width: 33%;
    max-width: 600px;
  }
}
@media screen and (max-width: 768px) {
  .email-container {
    width: 90%;
  }
}
</style>